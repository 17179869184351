<template>
  <a-modal
    v-model:visible="showVisualizer"
    title="Visualization"
    width="100%"
    wrap-class-name="full-modal"
    destroyOnClose
    :footer="null"
    :bodyStyle="{ padding: '1em', display: 'flex', flexDirection: 'column' }"
    @cancel="isVisualizerLoading = true"
    id="validatetb-visualizer-modal"
    zIndex="1000"
  >
    <template #closeIcon>
      <close-outlined id="validatetb-visualizer-modal-close-btn" />
    </template>
    <a-spin class="m-auto" size="large" v-if="isLoading" />
    <annotation-component
      :loading="isLoading"
      :isVisualize="true"
      :taskId="taskId"
      :video="videoToVisualize"
      :key="videoToVisualize"
      @stopLoading="isVisualizerLoading = false"
    ></annotation-component>
  </a-modal>

  <a-table
    :data-source="recordList"
    :columns="columns"
    :loading="loading"
    :pagination="pagination"
    :scroll="{
      scrollToFirstRowOnChange: true,
      x: true
    }"
    @change="handleTableChange"
    id="validatetb-records-table"
  >
    <template #title>
      <a-typography-title :level="5"
        >Validation History: &nbsp;
        <a-tag color="blue" id="validatetb-records-count">{{
          recordsCount
        }}</a-tag></a-typography-title
      ></template
    >

    <template #bodyCell="{ text, record, column, index }">
      <span
        v-if="column.dataIndex === 'thumbnail_path'"
        :id="'validatetb-record-img' + index"
      >
        <img
          :class="{
            'disable-click': isPlayDisabled(record),
            clickable: !isPlayDisabled(record)
          }"
          :src="thumbnailUrl(record)"
          alt="thumbnail.jpg"
          width="140"
          height="124"
          @click="emit('handleInferenceOpen', record)"
        />
      </span>

      <span
        v-if="column.dataIndex === 'video_url'"
        :id="'validatetb-record-filename' + index"
      >
        {{ splitFileNameByUUID(record.fileName) || getSplitFileName(text) }}
      </span>

      <span
        v-if="column.dataIndex === 'tags'"
        :id="'validatetb-record-tag' + index"
      >
        {{ record.tag }}
      </span>

      <span
        v-if="column.dataIndex === 'end_time'"
        :id="'validatetb-record-endtime' + index"
      >
        {{ dateHelper.formatDate(text) }}
      </span>

      <span v-if="column.dataIndex === 'status'">
        <a-tag
          :color="getBadgeClasses(record.processStatus.status.name)"
          :id="'validatetb-record-status' + index"
        >
          <template #icon>
            <check-circle-outlined v-if="isStatus(record, 'finished')" />
            <clock-circle-outlined v-if="isStatus(record, 'pending')" />
            <setting-outlined :spin="true" v-if="isStatus(record, 'started')" />
            <close-circle-outlined v-if="isStatus(record, 'failed')" />
          </template>
          {{ record.processStatus.status.name }}
        </a-tag>
        <!-- <a-tag
          color="cyan"
          class="clickable"
          v-if="isStatus(record, 'failed')"
          @click="retryProcess(record)"
        >
          <sync-outlined :spin="isRetrying" />
          Retry
        </a-tag> -->
      </span>

      <span v-if="column.dataIndex === 'analytics'">
        <a-button
          type="primary"
          :disabled="isDisable(record)"
          @click="goToTraceAnalytics(record, 'Analytics')"
          :id="'validatetb-record-analytics-btn' + index"
          >Analytics</a-button
        >
      </span>
      <span v-if="column.dataIndex === 'trace'">
        <a-button
          type="primary"
          :disabled="isDisable(record)"
          @click="goToTraceAnalytics(record, 'Trace')"
          :id="'validatetb-record-trace-btn' + index"
        >
          Trace</a-button
        >
      </span>

      <span v-if="column.dataIndex === 'actions'">
        <a-dropdown :trigger="['click']" placement="bottomRight">
          <template #overlay>
            <a-menu :id="'validatetb-record-menu-list' + index">
              <a-menu-item @click="fetchComments(record)">
                <CommentOutlined />
                Comments
              </a-menu-item>
              <a-menu-item @click="fetchTags(record)">
                <TagsOutlined />
                Tags
              </a-menu-item>

              <a-menu-item
                key="1"
                @click="handleVisualization(record)"
                :disabled="isDisable(record)"
              >
                <AlignLeftOutlined />
                Visualize
              </a-menu-item>
              <a-menu-item
                key="2"
                @click="handleDownloadCSV(record)"
                :disabled="isDisable(record)"
              >
                <DownloadOutlined />
                Download CSV
              </a-menu-item>
              <a-divider class="my-1" />

              <a-popconfirm
                okText="Yes"
                cancelText="No"
                title="Are you sure？You want to delete the record."
                @confirm="deleteValidationRecord"
                @cancel="recordToDelete = null"
                :okButtonProps="{ id: `delete-validation-${index}-yes-button` }"
                :cancelButtonProps="{
                  id: `delete-validation-${index}-no-button`
                }"
              >
                <template #icon
                  ><QuestionCircleOutlined style="color: red"
                /></template>
                <a-menu-item
                  key="3"
                  class="text-danger"
                  @click="recordToDelete = record"
                >
                  <DeleteOutlined />
                  Delete record
                </a-menu-item>
              </a-popconfirm>
            </a-menu>
          </template>
          <a-button :id="'validatetb-record-menu-btn' + index"
            ><template #icon> <MenuOutlined /> </template
          ></a-button>
        </a-dropdown>
      </span>
    </template>
  </a-table>
</template>
<script>
import { computed, defineComponent, inject, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import SpinnerComponent from '../../../shared/Components/Spinner.vue';
import Pagination from 'v-pagination-3';
import AnnotationComponent from '../annotation/Annotation.vue';
import httpClient from '@/service/httpClient';
import getFileNameFromUrl from '../../../shared/Helpers/getFileNameFromUrl';
import getCsvFileNameFromUrl from '../../../shared/Helpers/getCsvFileNameFromUrl';
import axios from 'axios';
import jsonToCsvDownload from '../../../shared/Helpers/jsonToCsvDownload';
import dateHelper from '../../../shared/Helpers/dateHelper';
import { roles } from 'src/config/roles-config';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  MenuOutlined,
  AlignLeftOutlined,
  DownloadOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  CommentOutlined,
  TagsOutlined,
  CloseOutlined
} from '@ant-design/icons-vue';
import { validationThumbnail, getThumbnailUrl } from 'src/utils/thumbnail';

export default defineComponent({
  components: {
    // ValidationRecord,
    SpinnerComponent,
    Pagination,
    AnnotationComponent,
    CheckCircleOutlined,
    ClockCircleOutlined,
    SyncOutlined,
    CloseCircleOutlined,
    MenuOutlined,
    AlignLeftOutlined,
    DownloadOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    SettingOutlined,
    CommentOutlined,
    TagsOutlined,
    CloseOutlined
  },
  emits: [
    'handleInferenceOpen',
    'updateStatusOfRecord',
    'goToTraceAnalytics',
    'pageChange',
    'changeLoading',
    'updateRecord',
    'showCommentsModal',
    'showTagsModal'
  ],
  props: [
    'recordList',
    'recordsCount',
    'next',
    'previous',
    'sortBy',
    'sortOrder',
    'recordsForPolling',
    'statuses',
    'loading',
    'fetchPresignedUrl',
    'taskId'
  ],
  setup(props, { emit }) {
    const store = useStore();
    const isThumbnailExist = ref(true);
    const currentRole = localStorage.getItem('role');
    const visualize = ref(null);
    const toast = inject('toast');
    const showMenu = ref(false);
    const videoToVisualize = ref(null);
    const showVisualizer = ref(false);
    const isVisualizerLoading = ref(true);
    const recordToDelete = ref(null);
    const isRetrying = ref(false);
    const organization = localStorage.getItem('organization');
    const paginate = reactive({
      current: 1,
      pageSize: 5
    });
    const indexToStepsMapping = computed(
      () => store.state.allTasks.indexToStepsMapping
    );

    const columns = [
      {
        title: 'Thumbnail',
        dataIndex: 'thumbnail_path',
        key: 'thumbnail_path'
      },
      {
        title: 'Filename',
        dataIndex: 'video_url',
        key: 'video_url'
      },
      {
        title: 'Tag',
        dataIndex: 'tags',
        key: 'tag'
      },
      {
        title: 'Finished Time',
        dataIndex: 'end_time',
        key: 'end_time'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status'
      },

      {
        title: 'Analytics',
        dataIndex: 'analytics',
        key: 'analytics'
      },
      {
        title: 'Trace',
        dataIndex: 'trace',
        key: 'trace'
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions'
      }
    ];

    const pagination = computed(() => ({
      total: props.recordsCount,
      current: paginate.current,
      pageSize: paginate.pageSize,
      showSizeChanger: false,
      position: ['bottomCenter']
    }));

    async function fetchComments(record) {
      if (!record.entity) {
        emit('showCommentsModal', {
          comments: [],
          taskRecord: record
        });
      } else {
        const comment_response = await httpClient.get(
          `organization/task_record/comments?entity_id=${record.entity.id}`
        );

        emit('showCommentsModal', {
          taskRecord: record,
          comments: comment_response
        });
      }
    }

    async function fetchTags(record) {
      console.log(record);
      //predefined tags available for particular organization
      const orgTag_response = await httpClient.get(
        `organization/task_record/tags?organization_id=${organization}`
      );

      if (!record.entity) {
        emit('showTagsModal', {
          taskRecord: record,
          orgTags: orgTag_response,
          entityTag: []
        });
      } else {
        //tags that are on current entity
        const entityTag_response = await httpClient.get(
          `organization/entity/tags?entity_id=${record.entity.id}`
        );

        emit('showTagsModal', {
          taskRecord: record,
          orgTags: orgTag_response,
          entityTag: entityTag_response
        });
      }
    }

    const isLoading = computed(() => isVisualizerLoading.value);

    function handleTableChange(pag, filters, sorter) {
      handlePageChange(pag);
    }

    function handlePageChange(pag) {
      const { current } = pag;
      paginate.current = current;
      emit('pageChange', current);
    }

    function updateStatusOfRecord(recordInfo) {
      emit('updateStatusOfRecord', recordInfo);
    }

    function goToTraceAnalytics(record, page) {
      emit('goToTraceAnalytics', {
        page,
        startTime: record.start_time.split('Z')[0],
        endTIme: record.end_time.split('Z')[0]
      });
    }

    function splitFileNameByUUID(fileName) {
      const ext = fileName?.split('.').at(-1);
      const regex = /.[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/;

      fileName = fileName?.split(regex)[0];

      if (fileName?.search(ext) == -1) {
        fileName += '.' + ext;
      }
      fileName = fileName.split('_').join(' ');

      return fileName;
    }

    async function deleteValidationRecord() {
      const url = `websocket_inference/validation_record/${recordToDelete.value.id}/`;
      const successMessage = `Validation Record deleted successfully!`;
      const res = await httpClient.delete(
        url,
        '',
        null,
        true,
        false,
        true,
        successMessage
      );
      if (res === 'error') {
        toast.error('Error occured while deleting the validation record!');
        return;
      }
      updateRecordList();
    }

    function updateRecordList() {
      if (props.recordList.length === 1) {
        const newPage = paginate.current - 1;
        paginate.current = newPage === 0 ? 1 : newPage;
      }
      emit('pageChange', paginate.current);
    }

    async function handleVisualization(video) {
      emit('changeLoading', true);
      if (!video.video_url) {
        const [
          video_url,
          per_frame_prediction_file_url
        ] = await fetchVideoAndPredsUrl(video);
        video = { ...video, video_url, per_frame_prediction_file_url };
      }
      const { fileName, video_url, per_frame_prediction_file_url } = video;
      const res = await updateVideoToVisualize(
        per_frame_prediction_file_url,
        videoToVisualize,
        video,
        fileName,
        video_url
      );
      emit('changeLoading', false);
      if (!res) return;
      showVisualizer.value = true;
    }

    async function fetchVideoAndPredsUrl(video, forCsv = false) {
      let predsReqArray = [
        props.fetchPresignedUrl(video),
        props.fetchPresignedUrl(video, true, 'Predictions')
      ];
      if (forCsv) {
        predsReqArray = [
          ...predsReqArray,
          props.fetchPresignedUrl(video, true, 'Processed')
        ];
      }
      return await Promise.all(predsReqArray);
    }

    function updateVideoToVisualize(
      per_frame_prediction_file_url,
      videoToVisualize,
      video,
      fileName,
      video_url
    ) {
      return new Promise(async (resolve, _) => {
        const temp = {
          ...video,
          fileName: fileName || getFileNameFromUrl(video_url),
          fileURL: video_url,
          resolution: '640x480',
          isProtectedVideo: true
        };
        try {
          if (video.predictions) {
            videoToVisualize.value = { ...temp };
            // return resolve();
          } else {
            const { data } = await axios.get(per_frame_prediction_file_url);
            videoToVisualize.value = { ...temp, predictions: data.preds };
          }
          resolve(true);
          emit('updateRecord', videoToVisualize.value);
        } catch (error) {
          toast.info("Prediction File Doesn't Exist!");
          resolve(false);
        }
      });
    }

    async function handleDownloadCSV(video) {
      if (!video.video_url) {
        const [
          video_url,
          per_frame_prediction_file_url,
          frames_steps_preds_url
        ] = await fetchVideoAndPredsUrl(video, true);
        video = {
          ...video,
          video_url,
          per_frame_prediction_file_url,
          frames_steps_preds_url
        };
      }

      store.dispatch('turnOnSpinner');
      const { per_frame_prediction_file_url, frames_steps_preds_url } = video;

      const [new_preds, old_preds] = await getPredictions([
        frames_steps_preds_url,
        per_frame_prediction_file_url
      ]);

      if (!new_preds && !old_preds) {
        toast.info(`Prediction File Doesn't Exist!`);
      }

      var fileURL = '',
        folderName = '',
        fileName = '',
        csvData = '';

      if (new_preds) {
        fileURL = frames_steps_preds_url;
        folderName = 'Processed';
        fileName = getCsvFileNameFromUrl(fileURL, folderName);
        csvData = getCSVData(new_preds, true);
      } else if (old_preds) {
        fileURL = per_frame_prediction_file_url;
        folderName = 'Predictions';
        fileName = getCsvFileNameFromUrl(fileURL, folderName);
        csvData = getCSVData(old_preds.preds);
      }
      if (csvData) jsonToCsvDownload(csvData, fileName);
      store.dispatch('turnOffSpinner');
    }

    function getPredictions(predictionsUrls) {
      return new Promise(async (resolve, _) => {
        var responses = [];
        for (const url of predictionsUrls) {
          try {
            const { data } = await axios.get(url);
            responses.push(data);
          } catch (error) {
            responses.push(undefined);
          }
        }
        resolve(responses);
      });
    }

    function getCSVData(preds, newPreds = false) {
      const entries = Object.entries(preds);
      if (entries.length === 0) {
        return [{ frame: '', label: '', step: '' }];
      }

      if (newPreds) {
        return entries.map(([key, stepIndex]) => {
          let labels;
          try {
            labels = Array.isArray(stepIndex)
              ? stepIndex
              : JSON.parse(stepIndex);
          } catch {
            labels = [];
          }

          labels = Array.isArray(labels) ? labels : [labels];

          return {
            frame: key,
            label: labels.join('; '),
            step: labels
              .map(l => indexToStepsMapping.value[l] || 'background')
              .join('; ')
          };
        });
      } else {
        return entries.map(([key, value]) => ({
          frame: key,
          label: value.pred ? value.pred[0] : '',
          step: indexToStepsMapping.value[value.pred[0]]
            ? indexToStepsMapping.value[value.pred[0]]
            : 'background'
        }));
      }
    }

    function thumbnailUrl(record) {
      if (!record) return;
      const thumbnailPath = validationThumbnail(record, organization);
      return getThumbnailUrl(thumbnailPath);
    }

    function getBadgeClasses(value) {
      return {
        finished: 'success',
        pending: 'default',
        failed: 'error',
        started: 'processing'
      }[value];
    }

    function getFileName(url) {
      const filename = getFileNameFromUrl(url);
      let name = filename?.slice(0, 30);
      if (filename?.length > 30) name += '...';
      return name;
    }

    function getSplitFileName(url) {
      return splitFileNameByUUID(getFileName(url));
    }

    function isStatus(record, status) {
      return record.processStatus.status.name === status;
    }

    async function retryProcess(record) {
      isRetrying.value = true;
      const { id } = props.statuses.find(el => el.name === 'retry');

      const res = await httpClient.patch(
        `process_status/`,
        `${record.id}/`,
        { status: id },
        false,
        false,
        false
      );

      if (res === 'error') toast.error('Unable to start the process!');
      else toast.success('Process started again successfully!');
      isRetrying.value = false;
    }

    function isPlayDisabled(record) {
      const { completed_status, status } = record.processStatus;
      // const { per_frame_prediction_file_url } = record;
      return completed_status.name !== status.name;
    }

    function isDisable(record) {
      const { completed_status, status } = record.processStatus;
      const { start_time, end_time } = record;
      return completed_status.name !== status.name || !start_time || !end_time;
    }

    return {
      emit,
      roles,
      toast,
      fetchComments,
      fetchTags,
      handlePageChange,
      columns,
      showMenu,
      currentRole,
      updateStatusOfRecord,
      goToTraceAnalytics,
      deleteValidationRecord,
      handleVisualization,
      handleDownloadCSV,
      getCSVData,
      thumbnailUrl,
      getFileName,
      getSplitFileName,
      splitFileNameByUUID,
      dateHelper,
      getBadgeClasses,
      pagination,
      handleTableChange,
      videoToVisualize,
      isVisualizerLoading,
      isLoading,
      visualize,
      showVisualizer,
      recordToDelete,
      isStatus,
      retryProcess,
      isRetrying,
      isPlayDisabled,
      isDisable,
      isThumbnailExist
    };
  }
});
</script>
<style></style>
