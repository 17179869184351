import S3Service from '../../../services/s3';
import { Process } from '../core/Process';
import * as csv from 'csv';

class TaskInfoInterface {
  constructor() {
    if (this.constructor === TaskInfoInterface) {
      throw new Error("Abstract classes can't be instantiated.");
    }
    this.minimumSubstepTimes = {};
    this.similarGroupOfSteps = {};
    this.taskDict = {};
    this.processes = [];
    this.unavailableCycleTimeKnobs = true;
    this.taskSubStepCount = 0;
    this.taskJson = {};
    this.negativeSteps = [];
    this.optionalSteps = [];
    this.minimumCycleTimePercentage = 0;
  }

  setMinimumSubStepTimes = (userInfo) => {};
  setSimilarSubSteps = () => {};
  setTaskDict = () => {};
  tryToFetchTaskJson = () => {};

  fetchTaskJson = () => this.allTasks.taskJson;
  fetchNegative = () => this.allTasks.negativeSteps;

  getMinimumCycleTime = () => {
    if (!this.unavailableCycleTimeKnobs) {
      this.minimum_cycle_time =
        this.minimumSubstepTimes.reduce((a, b) => a + b, 0) *
        this.minimumCycleTimePercentage;
    } else {
      this.minimum_cycle_time =
        this.minimumSubstepTimes.reduce((a, b) => a + b, 0) * 0.4;
    }
  };

  addSimilarSteps = (similar_steps) => {
    let data = [];
    for (const key1 in similar_steps) {
      let similar_items = [];
      for (const key2 in similar_steps[key1]) {
        similar_items.push(similar_steps[key1][key2]['stepIndex']);
      }
      for (let index = 0; index < similar_items.length - 1; index++) {
        if (similar_items[index + 1] - similar_items[index] <= 1) {
          console.log(
            'Similar Group of steps dont have an intermediate step seperator'
          );
          return null;
        }
      }
      data.push(similar_items);
    }
    return data;
  };

  parseProcesses = () => {
    let taskDict = this.taskDict;
    let processes = [];
    let prevProcessSubStepCount = 0;

    for (const [processId, process] of taskDict.entries()) {
      const processName = process['name'];
      let processSubSteps = process['steps'].reduce(
        (res, el) => [...res, ...el.substeps],
        []
      );
      processSubSteps = processSubSteps.map(
        (_, index) => prevProcessSubStepCount + index
      );
      prevProcessSubStepCount += processSubSteps.length;

      // Add Background to All Processes
      processSubSteps.push(this.allTasks.taskStepsCount);
      processes.push(
        new Process(
          processName,
          processSubSteps,
          processId,
          this.minimumSubstepTimes,
          this.negativeSteps,
          this.taskJson['cycle_calculation_params'] || {}
        )
      );
    }
    return processes;
  };
}

export class TaskInfoCloud extends TaskInfoInterface {
  constructor(allTasks) {
    super();
    this.allTasks = allTasks;
  }

  init = async (userInfo) => {
    await this.setMinimumSubStepTimes(userInfo);
    this.setSimilarSubSteps();
    this.setTaskDict();
    this.tryToFetchTaskJson();
    this.negativeSteps = this.fetchNegative();
    this.processes = this.parseProcesses();
  };
  setMinimumSubStepTimes = async (userInfo) => {
    // const [_, data] = await S3Service.readFile({
    //   bucket: userInfo.bucket,
    //   file_path: `${this.allTasks.taskName}/subStepTimes.json`,
    // });
    // this.minimumSubstepTimes = data;
    // this.taskSubStepCount = data.length;
  };

  setSimilarSubSteps = () => {
    const data = this.allTasks.similarSteps;
    this.similarGroupOfSteps = this.addSimilarSteps(data);
  };

  setTaskDict = () => {
    const data = this.allTasks.taskProcesses;
    this.taskDict = data;
  };

  tryToFetchTaskJson = () => {
    this.taskJson = this.fetchTaskJson();
    if (Object.keys(this.taskJson).length === 0) {
      this.unavailableCycleTimeKnobs = true;
      if (this.minimumSubstepTimes.length < 7) {
        this.minPercentageOfStepRequiredToEndCycle = 0.5;
      } else {
        this.minPercentageOfStepRequiredToEndCycle = 0.75;
      }
    }
  };

  getTelemetryLogs = async (userInfo) => {
    let response = await S3Service.readFile({
      bucket: userInfo.bucket,
      file_path: this.key,
    });
    let text = response['Body'].read().decode('utf-8').split('\n');
    this.telemetryLogs = csv.parse(text);
  };
}
